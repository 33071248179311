import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';

import welcome from '../../images/welcome.svg';

const WelcomeInfoSection = styled.section`
  background: #fafafa;
  font-family: ${props => props.theme.fontFamily};
  padding: 200px 0 100px 0;

  & h2{
    color: ${props => props.theme.primary};
    font-size: 34px;
  }

  & p{
    font-size: 16px;
    line-height: 1.8;
    margin-top: 25px;
  }

  & .welcome-background{
    background: url(${welcome});
    background-size: cover;
    transform: scale(0.8);
  }

  & .btn{
    background: ${props => props.theme.primary};
    color: #fff;
    font-size: 16px;
    margin-top: 10px;
    padding: 7px 10px;
    width: 50%;
    transition: background 0.3s ease;

    :hover {
      background: ${props => props.theme.secondary};
    }
  }

  @media(max-width: 798px){
    padding: 100px 0 50px 0;
    h2{
      font-size: 30px;
    }
  }
`;

const WelcomeInfo = () => {
  return (
    <WelcomeInfoSection>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <h2>Welcome To LIC Tax & Accounting Services</h2>
            <p>
              Here at LIC Tax and Accounting Services, we enjoy educating our clients on “HOW” they can save on their taxes and manage their finances for a wealthier future. Our firm provides the best accounting, taxation, audit representation, and consulting services in Long Island City to both businesses and individuals. The vast services we provide are tailored to the unique needs of each client, ensuring the best possible results. We provide exceptional high-quality service, no matter how large or small your needs may be. Check out LIC Tax & Accounting Services on Yelp
            </p>
            <div className="text-center">
              <a href="tel:718-362-3355" className="btn">Call Now</a>
            </div>
          </Col>
          <Col xs={12} lg={6} className="welcome-background"></Col>
        </Row>
      </Container>
    </WelcomeInfoSection>
  )
}

export default WelcomeInfo
