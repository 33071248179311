import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
// Pages
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Services from './Pages/Services';
import ContactUs from './Pages/ContactUs';

const theme = {
  fontFamily: 'Poppins, sans-serif',
  primary: '#4AAECE',
  secondary: '#013368'
}

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/about" component={AboutUs}/>
          <Route exact path="/services" component={Services}/>
          <Route exact path="/contact" component={ContactUs}/>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
