import React from 'react'
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import partnersImage from '../../images/about-us-header.jpg';

const HeaderSection = styled.section`
  font-family: ${props => props.theme.fontFamily};
  background: url(${partnersImage});
  z-index: -2;
  height: 400px;
  background-attachment: fixed;
  display: grid;
  place-items: center;
  background-size: cover;
  background-repeat: no-repeat;

  & h1{
    font-size: 42px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 1.1px;
    color: white;
  }

  @media(max-width: 600px){
      height: 300px;
      h1{
        font-size: 32px;
      }
  }
`;

const Header = () => {
  return (
    <HeaderSection>
      <Container>
        <h1 className="text-center">Partner With Our Team Of Seasoned<br/><span>Tax Specialists</span></h1>
      </Container>
    </HeaderSection>
  )
}

export default Header
