import React from 'react';
import Footer from '../Layout/Footer';
import Nav from '../Layout/Navigation';
import AboutInfo from './about_components/AboutInfo';
import Header from './about_components/Header';

const AboutUs = () => {
  return (
    <>
      <Nav active="About"/>
      <Header />
      <AboutInfo />
      <Footer />
    </>
  )
}

export default AboutUs;
