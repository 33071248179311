import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Navigation from '../../Layout/Navigation';
import skyline from '../../images/skyline.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import emailjs from 'emailjs-com';

const MainHeader = styled.div`
  position: relative;
  min-height: 100vh;
  max-height: max-content;
  @media(max-width: 500px){
    padding-bottom: 65px;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://upload.wikimedia.org/wikipedia/commons/0/0f/Gantry_Plaza_State_Park_Long_Island_City_01_%289431668991%29.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -80px;
  opacity: 0.6;
  z-index: -1;

  @media(max-width: 600px){
    background-position-y: 0;
  }
`;

const HeaderContent = styled.div`
  min-height: ${props => props.pageHeight ? props.pageHeight : 0}px;
  display: flex;
  align-items: center;
`;

const HeaderInfo = styled.div`
  font-family: ${props => props.theme.fontFamily};
  
  & h1{
    color: ${props => props.theme.secondary};
    font-size: 55px;
    letter-spacing: 1px;
    line-height: 80px;

    @media(max-width: 500px){
      font-size: 32px;
      margin-top: 50px;
      line-height: 50px;
    }
  }
`;

const CardContainer = styled.div`
  font-family: ${props => props.theme.fontFamily};

  & .submit-button{
    background: ${props => props.theme.primary}
  }

  & input, textarea{
    background: transparent;
    padding: 10px;

    :focus{
      background: transparent;
    }

    ::placeholder{
      opacity: 0.6;

      @-moz-document url-prefix() {
        opacity: 1;
      }
    }
  }

  @-moz-document url-prefix() {
    & .frosted-glass{
      position: relative;
      border-radius: 10px;
      background: url(${skyline});
      z-index: 0;
      overflow: hidden;
      ::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.7);
        background: inherit;
        filter: blur(20px) !important;
        z-index: -1;
      }
    }
  }

  & .frosted-glass{
    position: relative;
    border-radius: 10px;
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(25px);
    z-index: 0;
    overflow: hidden;
  }
`;

const Home = () => {

  const [navHeight, setNavHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSent, setSent] = useState(false);

  useEffect(() => {
    setNavHeight(document.getElementById('nav').clientHeight);
    console.log()
  }, [navHeight]);


  const sendEmail = (e) => {
    e.preventDefault();

    const [fName, lName, email, phone] = document.querySelectorAll('input');
    const message = document.querySelector('textarea');

    if (!fName.value || !lName.value || !email.value || !message.value || !phone.value) {
      alert('Please Fill In All Fields');
      return;
    }
    else {
      setLoading(true);

      emailjs.sendForm('gmail', 'lictax_template', e.target, 'user_6yoFlpNlEAmm2dMT7ORLo')
        .then((_) => {
          setLoading(false);
          setSent(true);
          e.target.reset();
          setTimeout(() => {
            setSent(false)
          }, 2000);
        }, (error) => {
          console.log(error.text);
        });
    }
  }


  return (
    <MainHeader>
      <Navigation id="nav" active="Home" />
      <HeroBackground navHeight={navHeight} />

      <HeaderContent pageHeight={window.innerHeight - navHeight}>
        <Container className="h-100">
          <Row className="d-flex align-items-center">
            <Col xs={12} lg={6} className="order-1 order-lg-2">
              <HeaderInfo>
                <h1>
                  Long Island City's Leading Tax and Accounting Firm
                </h1>
              </HeaderInfo>
            </Col>

            <Col xs={12} lg={6} className="order-2 order-lg-1">
              <CardContainer className="mt-5 mt-lg-0">
                <Card className="frosted-glass shadow">
                  <Card.Body>
                    <Form onSubmit={sendEmail}>
                      <Row className="mb-2">
                        <Col xs={12} lg={6}>
                          <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="John" name="first_name" />
                          </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Doe" name="last_name" />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Form.Group className="mb-4">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="johndoe@gmail.com" name="email" />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="555-555-5555" name="phone" />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Reason</Form.Label>
                        <Form.Control as="textarea" rows={7} placeholder="We handle both business and individual taxes. Let us know what tax type you need help with and one of our representatives will reach out as soon as possible!" name="message" />
                      </Form.Group>

                      <Button type="submit" className="w-100 submit-button mt-4 mb-2 py-2">
                        {
                          loading ?
                            <Spinner size="sm" animation="border" variant="light" />
                            : showSent ? <span><i className="fas fa-check text-success mr-2"></i> Sent</span> : 'Submit'
                        }
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </CardContainer>
            </Col>
          </Row>
        </Container>
      </HeaderContent>
    </MainHeader>
  )
}

export default Home
