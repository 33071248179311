import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

import business from '../../images/business.svg';
import personalPrep from '../../images/personal_prep.svg';
import taxRes from '../../images/tax_resolution.svg';
import businessInfo from '../../images/business_info.svg';


const ServiceSection = styled.section`
  padding: 100px 0;
  background-color: #fafafa;
  font-family: ${props => props.theme.fontFamily};
  position: relative;

  & h1{
    color: ${props => props.theme.primary};
  }

  & .service-card{
    padding: 20px;
    box-sizing: border-box;
    background: inherit;
    border-radius: 10px;
    border: none;
    box-shadow: -3px -3px 10px #ffffff23,
                3px 3px 10px rgba(141, 98, 121, 0.3);
    transition: all 0.4s;
    min-height: 350px;
    margin-bottom: 50px;

    & h2{
      margin-bottom: 40px;
      font-size: 26px;
      color: ${props => props.theme.secondary};
    }

    & li{
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
`;

const Services = () => {
  return (
    <ServiceSection>
      <Container>
        <h1 className="text-center mb-5">Our services</h1>

        <div>
          <Card className="service-card">
            <Card.Body className="d-flex align-items-center">
              <Row className="h-100 d-flex align-items-center">
                <Col xs={12} lg={4} className="text-center">
                  <img src={business} width="200px" alt="" />
                </Col>
                <Col xs={12} lg={8}>
                  <h2 className="text-center">Business Services</h2>
                  <Row>
                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>Business Tax Preparation Services</li>
                        <li>Quarterly Estimated Tax</li>
                        <li>Financial Statement Preparation Services</li>
                        <li>Monthly Bookkeeping Services</li>
                      </ul>
                    </Col>

                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>Payroll Management Services</li>
                        <li>Tax Advisory Services</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="service-card">
            <Card.Body>
              <Row className="h-100 d-flex align-items-center">
                <Col xs={12} lg={4} className="text-center">
                  <img src={personalPrep} width="200px" alt="" />
                </Col>
                <Col xs={12} lg={8}>
                  <h2 className="text-center">Individual Preparation</h2>
                  <Row>
                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>1099 Contractor Income</li>
                        <li>Rental Property owners</li>
                        <li>Multi-State Tax Return</li>
                        <li>Foreigner living in the US</li>
                      </ul>
                    </Col>

                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>US Expats</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="service-card">
            <Card.Body>
              <Row className="h-100 d-flex align-items-center">
                <Col xs={12} lg={4} className="text-center">
                  <img src={taxRes} width="200px" alt="" />
                </Col>
                <Col xs={12} lg={8}>
                  <h2 className="text-center">Tax Resolution</h2>
                  <Row>
                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>File Back Tax Return</li>
                        <li>Tax Settlement</li>
                        <li>Audit Representation</li>
                        <li>Installment Agreement</li>
                      </ul>
                    </Col>

                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>Penalty and Interest Abatement</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card className="service-card">
            <Card.Body>
              <Row className="h-100 d-flex align-items-center">
                <Col xs={12} lg={4} className="text-center">
                  <img src={businessInfo} width="200px" alt="" />
                </Col>
                <Col xs={12} lg={8}>
                  <h2 className="text-center">New Business Information</h2>
                  <Row>
                    <Col xs={12} lg={6} className="pl-1 pl-lg-0">
                      <ul>
                        <li>LLC and LLP</li>
                        <li>S-Corp</li>
                        <li>Doing Business As</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </ServiceSection>
  )
}

export default Services
