import React from 'react';
import { Link } from 'react-router-dom';
import { openPopupWidget  } from 'react-calendly';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import logo from '../images/logo.png';

const NavLinks = styled.div`
  font-size: 18px;
  font-weight: 400;
  font-family: ${props => props.theme.fontFamily};
  & a{
    transition: all 0.2s ease;  
    color: #000;
    text-decoration: none;
    padding: 0 10px;

    &.active{
      color: ${props => props.theme.primary};
    }

    :hover{
      color: ${props => props.theme.primary}
    }

    @media(max-width: 994px){
      display: block;
      margin-bottom: 25px;
    }
  }

  & .click-link{
    border-radius: 5px;
    background: ${props => props.theme.primary};
    color: #fff !important;
    padding: 5px 10px;
    outline: none;
    border: none;
    transition: all 0.3s;
    margin-left: 12px;

    :hover{
      background: ${props => props.theme.secondary};
    }

  }
  @media(max-width: 994px){
    padding-top: 25px;
    & .click-link{
      margin-left: 0;
    }
  }
`;

const Navigation = (props) => {

  const calendlyURL = 'https://calendly.com/lictax/initial-phone-consultation';
  const onClick = () => {
    openPopupWidget({
      url: calendlyURL
    });
  }

  return (
    <Navbar id="nav" expand="lg" className="py-4">
      <Container>
        <Link to="/">
          <img 
            src={logo} 
            height="55px"
            alt="LIC Tax"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <NavLinks className="ml-auto d-block d-lg-flex align-items-lg-center">
            <Link to="/" className={props.active === 'Home' ? 'active' : null}>Home</Link>
            <Link to="/about" className={props.active === 'About' ? 'active' : null}>About Us</Link>
            <Link to="/Contact" className={props.active === 'Contact' ? 'active' : null}>Contact</Link>
            <button className="click-link" onClick={onClick}>
              Make Appointment
            </button>
          </NavLinks>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
