import React from 'react';
import reviews from '../../images/reviews.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

const TestimonialSection = styled.section`
  padding: 200px 0 100px 0;
  background: #fff;
  font-family: ${props => props.theme.fontFamily};
  position: relative;

  .more-reviews{
    color: ${props => props.theme.secondary};
    text-decoration: none;
  }

  & .custom-shape-divider-top-1608483925 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 108px;

      .shape-fill {
        fill: #fafafa;
      }
    }
  }

  & h1{
    color: ${props => props.theme.primary};
    font-size: 36px;
  }

  & h2{
    color: ${props => props.theme.secondary};
    margin-bottom: 30px;
  }

  & .carousel-card{
    padding: 50px;
    margin-bottom: 120px;
    position: relative;
    border: none;
    border-radius: 10px;
    background: inherit;
    box-shadow: -3px -3px 10px #ffffff23,
                3px 3px 10px rgba(141, 98, 121, 0.3);
    transition: all 0.3s ease;

    p {
      line-height: 1.8;
      font-size: 16px;
    }

    .carousel-control-next{
      width: 50px;
      height: 50px;
      position: absolute;
      border: none;
      background: ${props => props.theme.primary};
      border-radius: 50%;
      top: 50%;
      font-size: 20px;
      display: grid;
      place-items: center;
      outline: none;
      color: black;
      right: -7.5%;
      transform: translate(50%, -50%);
    }

    .carousel-control-prev{
      width: 50px;
      height: 50px;
      position: absolute;
      border: none;
      background: ${props => props.theme.primary};
      border-radius: 50%;
      top: 50%;
      font-size: 20px;
      display: grid;
      place-items: center;
      outline: none;
      color: black;
      left: -67%;
      transform: translate(50%, -50%);
    }

    .stars{
      color: gold;
      font-size: 16px;

      i {
        margin-left: 3px;
      }
    }
  }

  .socials{
    font-size: 40px;
    display: flex;
    justify-content: center !important;
    
    a{
      text-decoration: none;
      transition: all 0.3s !important;
    }

    .fa-instagram{
      color: #C84990;

      :hover{
        color: #dd1687;
      }
    }

    .fa-facebook{
      margin: 0 60px;
    }

    .fa-twitter{
      color: #50ABF1;

      :hover{
        color: #1a96f5;
      }
    }
  }

  @media (max-width: 1199px) {

    .carousel-control-next{
      right: -9.5% !important;
    }

    .carousel-control-prev{
      left: -71% !important;
    }
  }

  @media (max-width: 980px) {
    .carousel-control-next{
      right: -8.5% !important;
    }

    .carousel-control-prev{
      left: -16% !important;
    }
  }

  @media (max-width: 767px) {

    padding-top: 100px;

    & .custom-shape-divider-top-1608483925 svg {
        width: calc(100% + 1.3px);
        height: 42px;
    }

    .carousel-control-next{
      right: -12.5% !important;
    }

    .carousel-control-prev{
      left: -24% !important;
    }
  }

  @media (max-width: 499px) {
    .carousel-control-next{
      top: -45px !important;
      right: -15.5% !important;
    }

    .carousel-control-prev{
      top: -45px !important;
      left: -35% !important;
    }
  }
`;

const Testimonials = () => {

  return (
    <TestimonialSection>
      <div className="custom-shape-divider-top-1608483925">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
      <Container>
        <h1 className="text-center">Testimonials</h1>
        <h2 className="text-center">Our Satisfied Clients</h2>
        <Card className="carousel-card">
          <Row>
            <Col xs={12} lg={4}>
              <img src={reviews} alt="Reviews" className="w-100" />
              <h4 className="text-center mt-5 mb-5 mb-lg-0">
                <a className="more-reviews" rel="noreferrer" href="https://search.google.com/local/reviews?placeid=ChIJAQDkJNVYwokRWBpypjfDqNM" target="_blank">More Reviews</a>
              </h4>
            </Col>

            <Col xs={12} lg={8}>
              <Carousel indicators={false} className="h-100">
                <Carousel.Item>
                  <p>
                    “Working with Giovanni has been a pleasure, very attentive and professional. He took his time to answer all my questions and explain the processes filing for my taxes, also I just started my first business and moving forward I will be sending my business and referrals over to him. Much better experience than going to a firm like H&R Block”
                  </p>
                  <h5 className="text-right">
                    <cite>Mark M.</cite>
                  </h5>
                  <div className="text-right mt-3 stars">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>

                </Carousel.Item>
                <Carousel.Item>
                  <p>
                    “Giovanni was a pleasure in his professionalism and attention to detail. He took the time to learn my situation and then explained everything in a way that was easy to understand. I will highly recommend Giovanni and the LIC Tax team to friends and family!”
                  </p>
                  <h5 className="text-right">
                    <cite>Carol H.</cite>
                  </h5>
                  <div className="text-right mt-3 stars">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <p>
                    “Giovanni is professional, efficient and worked quickly on our tax issue. He took his time to clearly explain each step of the process and followed up to ensure we were comfortable with the work he was doing. We have utilized tax professionals in the past and they are nowhere near as responsive, professional or efficient as Giovanni. We will be using his services going forward and I recommend if you’re in the market for a tax pro to check him out and see if it’s a fit for you and your needs.”
                  </p>
                  <h5 className="text-right">
                    <cite>Kevin S.</cite>
                  </h5>
                  <div className="text-right mt-3 stars">
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Card>

        <Row>
          <div className="socials d-flex justify-content-center w-100">
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/lic_tax">
              <i className="fab fa-instagram"></i>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/LICTax">
              <i className="fab fa-facebook"></i>
            </a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/LICTax1">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </Row>

      </Container>
    </TestimonialSection>
  )
}

export default Testimonials;
