import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import emailjs from 'emailjs-com';

import styled from 'styled-components';

const MainSectionContainer = styled.section`
  margin: 100px 0;
  font-family: ${props => props.theme.fontFamily};

  & a, p{
    text-decoration: none;
    color: #838282;
    font-size: 16px;
  }

  & h2{
    color: ${props => props.theme.secondary};
    font-size: 35px;
  }

  & h4{
    margin-top: 15px;
    margin-bottom: 35px;
    color: #979797;
  }

  & h3{
    color: #979797;
  }

  & .bg-teal{
    background-color: #4AAECE20
  }

  & .b-0{
    border: none !important;
  }

  & .br-4{
    border-radius: 10px;
    overflow: hidden;
  }

  & .card-padding{
    padding: 30px;
  }

  & input{
    padding: 20px 10px;

    ::placeholder{
      opacity: 0.6;
    }
  }

  & textarea::placeholder{
    opacity: 0.6;
  }

  & button{
    background: ${props => props.theme.secondary};
  }

  @media(max-width: 1200px){
    
    margin-top: 40px;

    & .card-padding{
      padding: 20px;
    }
  }
`;

const MainSection = () => {

  const [loading, setLoading] = useState(false);
  const [showSent, setSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const [fName, lName, email, phone] = document.querySelectorAll('input');
    const message = document.querySelector('textarea');

    if (!fName.value || !lName.value || !email.value || !message.value || !phone.value) {
      alert('Please Fill In All Fields');
      return;
    }
    else {
      setLoading(true);
      
      emailjs.sendForm('gmail', 'lictax_template', e.target, 'user_6yoFlpNlEAmm2dMT7ORLo')
        .then((_) => {
          setLoading(false);
          setSent(true);
          e.target.reset();
          setTimeout(() => {
            setSent(false)
          }, 2000);
        }, (error) => {
          console.log(error.text);
        });
    }
  }

  return (
    <MainSectionContainer>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="pr-lg-5 pl-lg-0">
            <h2>Get In Touch</h2>
            <h4>Let's talk about how we can help you</h4>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.5241449429795!2d-73.93911228470608!3d40.750495179327785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258d524e40001%3A0xd3a8c337a6721a58!2sLIC%20Tax%20%26%20Accounting%20Services!5e0!3m2!1sen!2sus!4v1610130843019!5m2!1sen!2sus" width="100%" height="350" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="location"></iframe>

            <Row className="mt-5">
              <Col xs={12} lg={6}>
                <h5>Call Us:</h5>
                <a href="tel:718-362-3355">(718) 362-3355</a>
              </Col>
              <Col xs={12} lg={6} className="mt-4 mt-lg-0">
                <h5>Address:</h5>
                <p>28-07 Jackson Ave 9<sup>th</sup> floor<br /> Long Island City, NY 11101</p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12} lg={6}>
                <h5>Email Us:</h5>
                <a href="mailto: email@email.com">info@lictax.com</a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="pl-lg-3 pr-lg-0">
            <Card className="b-0 shadow-lg br-4 mt-5 mt-lg-0">
              <Card.Header className="b-0 p-4 bg-teal"><h3>General Inquiries</h3></Card.Header>
              <Card.Body className="card-padding">

                <Form onSubmit={sendEmail}>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="John" name="first_name" />
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Doe" name="last_name" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="johndoe@gmail.com" name="email" />
                  </Form.Group>

                  <Form.Group className="mt-lg-4 mb-lg-5">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" placeholder="555-555-5555" name="phone" />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={6} placeholder="We handle both business and individual taxes. Let us know what tax type you need help with and one of our representatives will reach out as soon as possible!" name="message" />
                  </Form.Group>

                  <Button className="w-100 py-3 mt-3" type="submit">
                    {
                      loading ?
                        <Spinner size="sm" animation="border" variant="light" />
                        : showSent ? <span><i className="fas fa-check text-success mr-2"></i> Sent</span> : 'Submit'
                    }
                  </Button>
                </Form>
              </Card.Body>
            </Card>
            <div className="text-center">
              <p className="text-muted mt-5">We will get back to you within 1-2 business days</p>
            </div>
          </Col>
        </Row>
      </Container>
    </MainSectionContainer>
  )
}

export default MainSection;
