import React from 'react';
import Hero from './home_components/Hero';
import Services from './home_components/Services';
// import SmallBusinessHelp from './home_components/SmallBusinessHelp';
// import StepProgress from './home_components/StepProgress';
import Testimonials from './home_components/Testimonials';
import WelcomeInfo from './home_components/WelcomeInfo';


const Home = () => {
  return (
    <>
      <Hero />
      <WelcomeInfo />
      <Services />
      {/* <SmallBusinessHelp /> */}
      {/* <StepProgress /> */}
      <Testimonials />
    </>
  )
}

export default Home;
