import React from 'react';
import { Link } from 'react-router-dom';
import { openPopupWidget  } from 'react-calendly';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import logo from '../images/logo-alt.png';
import Button from 'react-bootstrap/Button';

const FooterSection  = styled.section`
  background: #fafafa;
  padding: 50px 0;
  font-family: ${props => props.theme.fontFamily};
  font-size: 16px;

  & img{
    height: 40px;
  }

  & p{
    line-height: 1.6;
  }

  & i{
    color: ${props => props.theme.primary};
  }

  & .footer-button{
    background: ${props => props.theme.primary};
  }
`;

const Footer = () => {

  const calendlyURL = 'https://calendly.com/lictax/initial-phone-consultation';
  const onClick = () => {
    openPopupWidget({
      url: calendlyURL
    });
  }

  return (
    <FooterSection>
      <Container>
        <Row>
          <Col xs={12} lg={4}>
            <img src={logo} alt="logo"/>
            <p className="mt-4 pr-lg-4">
              We are helping individuals & small business owners solve their tax debts by handling their case from the IRS & State to give you peace of mind!
            </p>
          </Col>
          <Col xs={12} lg={4} className="pl-lg-5 mt-4 mt-lg-0">
            <h4 className="mb-3">Contact Us</h4>
            <p><i className="fas fa-phone mr-2"></i> (718) 362-3355</p>
            <p><i className="fas fa-building mr-2"></i> 28-07 Jackson Ave 9<sup>th</sup> floor <br/> Long Island City, NY 11101</p>
            <p><i className="fas fa-envelope-open-text mr-2"></i> info@lictax.com</p>
            <h4 className="mt-5 mt-lg-4 mb-3">Business Hours</h4>
            <p><i className="fas fa-clock mr-2"></i>
              <span className="mr-2"> Mon - Sat</span><span>(9am - 6pm)</span>
            </p>
          </Col>
          <Col xs={12} lg={4} className="mt-5 mt-lg-0">
            <h4 className="mb-3">Get In Touch</h4>
            <p>Have any questions, shoot us a message</p>
            <Link to="/contact">
              <Button className="mb-5 footer-button">
                Contact Us
              </Button>
            </Link>

            <p>Schedule an appointment with us now</p>
            <Button onClick={onClick} className="footer-button">
              Schedule An Appoinment
            </Button>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  )
}

export default Footer;
