import React from 'react'
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import aboutUsImage from '../../images/about-us.jpg';

const AboutInfoSection = styled.section`
  font-family: ${props => props.theme.fontFamily};
  font-size: 16px;
  margin: 100px 0;

  & .about-us{
    transform: scale(0.9);
  }
`;

const AboutInfo = () => {
  return (
    <AboutInfoSection>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="px-4">
            <h2 className="mb-4">About Us</h2>
            <p>
              LIC Tax was founded by Giovanni Inga and his wife Janice Morocho – Inga. Giovanni and Janice are both born and raised in Queens, NY and decided to start their business in Long Island City because of their love towards the Queens community but especially their love towards the growing community in Long Island City. They started LIC Tax with the goal of helping business owners and individual under financial stress find peace of mind.
            </p>

            <p>
              Giovanni attended St Francis College in Brooklyn, NY and graduated with a Master of Science majoring in Accounting. Giovanni is and Enrolled Agent, who can represent clients with tax issue before the IRS or state taxing agency. He has 8 years of experience helping business owners and families dealing with financial stress due to their tax issues with the IRS and/or NYS. In his free time off he loves to play sports and hangout with his wife and daughters.
            </p>

            <p>
              Janice graduated St. Francis College with a Bachelor of Science in Criminal Justice and minor concentrations in Sociology, Forensic Science and Information Technology.  Before founding LIC Tax, Janice interned in the King’s County District Office, NYC Department of Probation and worked as a paralegal in a Law firm. All her past experiences have helped her built an exceptional background in customer service, office administration and problem solving. Every day, she puts the needs of LIC Tax clients first. She absolutely enjoys spending her time with her family and getting a good workout at the gym!
            </p>

            <p>
              Contact our team today to partner with the best Long Island City Tax firm. Call <a href="tel:+7183623355">(718) 362-3355</a>, send us a chat message or fill-out our contact form to connect with us.
            </p>
          </Col>
          <Col xs={12} lg={6} className="p-0 shadow about-us">
            <img src={aboutUsImage} alt="LIC Team" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </AboutInfoSection>
  )
}

export default AboutInfo;
