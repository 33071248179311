import React from 'react';
import Footer from '../Layout/Footer';
import Navbar from '../Layout/Navigation';
import MainSection from './contact_us_components/MainSection';

const ContactUs = () => {
  return (
    <>
      <Navbar active="Contact"/>
      <MainSection />
      <Footer />
    </>
  )
}

export default ContactUs;
